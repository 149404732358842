import React from 'react';
import Img from 'gatsby-image';
import { string } from 'prop-types';

import { MediaImageType } from '../../types';

const MediaImage = ({ media, alt = '', title = '' }) => {

  const isOptimizedImage = () => {
    return media?.localFile?.childImageSharp;
  };

  const renderOptimizedImage = () => {
    return (
      <div className="image-content">
        { media?.localFile?.childImageSharp?.fixed && <Img fixed={media.localFile.childImageSharp.fixed} alt={alt} title={title} /> }
        { media?.localFile?.childImageSharp?.fluid && <Img fluid={media.localFile.childImageSharp.fluid} alt={alt} title={title} /> }
      </div>
    );
  };

  const renderDefaultImage = () => {
    return (
      <>
        { media?.localFile?.publicURL && <img src={media.localFile.publicURL} alt={alt} title={title} /> }
      </>
    );
  };

  return isOptimizedImage() ? renderOptimizedImage() : renderDefaultImage();

};

MediaImage.propTypes = {
  media: MediaImageType,
  alt: string,
  title: string
};

export default MediaImage;
